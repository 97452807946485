<template>
    <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <!-- <th class="">Txn Type</th> -->
                        <th class="">Voucher No</th>
                        <th class="">Account Head</th>
                        <th class="">Debit</th>
                        <th class="">Credit</th>
                        <th>Line Description</th>
                    </tr>
                </thead>
                <tbody>
                    <LedgerRow 
                        v-for="(key, i) in ledgerKeys"
                        :key="i"
                        :ledger="ledgers[key]"
                        :keyName="key"
                        :accountHeads="accountHeads"
                        @onclickUpdate="onclickUpdate"
                     />
                </tbody>
            </table>
        </div>
</template>

<script>
import LedgerRow from '@/components/molecule/journal-import/LedgerRow.vue'
export default {
    name: 'Ledger',
    emits:['onclickUpdate'],
    components: {
        LedgerRow
    },
    computed: {
        ledgerKeys () {
            return this.ledgers ? Object.keys(this.ledgers) : []
        }
    },
    methods: {
        onclickUpdate(data) {
            this.$emit('onclickUpdate', data)
        }
    },
    props: {
        ledgers: Object,
        accountHeads: Array
    }
}
</script>
