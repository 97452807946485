<template>
    <tr v-for="(item, i) in ledger.ledgers" :key="i">
        <td :class="{'bg-danger-light' : !item.validation_date}">
            <input
                type="date"
                ref="date"
                :item="`${i}`"
                :value="item.date"
                @change="onChangeDate"
            >
        </td>
        <td>{{ keyName }}</td>
        <td :class="{'bg-danger-light' : !item.validation_account_head}">
            <v-select
                @option:selecting="onChange(item)"
                v-model="item.account_head_id"
                :options="accountHeads" 
                label="name"
                :reduce="name => name.id"
            />
        </td>
        <td>
            <input v-if="item.debit != null" class="text-end" type="text" v-model="item.debit">
        </td>
        <td><input v-if="item.credit != null" class="text-end" type="text" v-model="item.credit"></td>
        <td>
           <textarea
                v-model="item.note"
            >
            </textarea>
        </td>
    </tr>
    <tr>
        <td colspan="6">
            <div class="d-flex justify-content-between align-items-center mb-3">
                <div>
                    <strong> Status: </strong>
                    {{ ledger.status }} |
                    <strong>Total Debit: </strong>
                    {{ totalDebit }} | 
                    <strong>Total Credit: </strong>
                    {{ totalCredit }}
                 </div>
                <button @click="onclickUpdate" class="btn btn-success">Update</button>
            </div>
        </td>
    </tr>
</template>

<script>
export default {
    name: 'LedgerRow',
    emits:['onclickUpdate'],
    computed: {
        totalDebit () {
            let total = 0;
            this.ledger.ledgers.map(i => {
                if(i.debit) total += parseFloat(i.debit)
            })
            return total.toFixed(2)
        },
        totalCredit () {
            let total = 0;
            this.ledger.ledgers.map(i => {
                if(i.credit) total += parseFloat(i.credit)
            })
            return total.toFixed(2)
        },
    },
    methods: {
        onChange(item) {
            setTimeout(() => {
                let selected = this.accountHeads.find(i => i.id == item.account_head_id)
                item.account_head = selected.name
            }, 100)
        },

        onclickUpdate() {
            this.$emit('onclickUpdate', {ledger: this.ledger, keyName:this.keyName})
        },

        onChangeDate(event) {
            let index = event.target.getAttribute("item");
            this.ledger.ledgers[index].date = event.target.value
        }
    },
    props: {
        ledger: Object,
        // totalDebit: Number,
        // totalCredit: Number,
        keyName: String,
        accountHeads: Array
    }
}
</script>