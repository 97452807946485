<template>
    <div style="min-height: calc(100vh - 158px);" class="card">
        <div v-if="loading" style="position: fixed; top: 0; bottom: 0; left:0; right: 0; background: black z-index: 9999; opacity: 0">dd</div>
        <div class="p-2">
            <div class="d-flex flex-wrap justify-content-between align-items-center">
                <h3>{{ isCreatePage ? 'Imported transaction list' : 'Edit Transaction' }}</h3>
                <div class="d-flex gap-1">
                     <a
                        download
                        href="/file/JV import format-miaccounts.xlsx"
                        style="min-width: 64px;"
                        class="btn btn-outline-secondary waves-effect waves-float waves-light"
                    >Download Sample
                    </a>
                    <button
                        @click="handleClick"
                        style="min-width: 64px;"
                        class="btn btn-primary waves-effect waves-float waves-light"
                    >Browse
                    </button>
                    <button
                        @click="goToList"
                        class="btn btn-close"
                    >
                    </button>
                </div>
            </div>
            <hr class="mt-1">
            <div v-if="isUploading" class="row mt-4">
                <div class="col-md-6">
                        <label for="">{{fileName}}</label>
                    <div class="progress progress-bar-primary">
                        <div 
                            class="progress-bar" 
                            role="progressbar"
                            aria-valuemax="100" 
                            :style="{width: progress+'%'}" :aria-describedby="fileName"></div>
                    </div>
                </div>
            </div>
            <input type="file" class="d-none" ref="uploader" @change="handleFileUpload">
            
            <template v-if="sheet.id">
                <Ledger
                    :ledgers="sheet.ledgers"
                    :accountHeads="accountHeads"
                    @onclickUpdate="onclickUpdate"
                /> 
            </template>
           
           <hr>

           <button
                :disabled="loading"
                v-if="sheet.id" 
                @click="onClickStore" 
                class="btn btn-primary mt-1"
            >
                Save
            </button>
        </div>
        <Loader v-if="loading"/>
    </div>
</template>

<script>
import handleJournal from '@/services/modules/journal'
import { inject } from 'vue'
// import { useStore } from 'vuex'
// import { computed } from 'vue'
import {mapMutations} from 'vuex'

import TitleButton from '@/components/atom/TitleButton'
import Ledger from '@/components/molecule/journal-import/Ledger'
import AddButton from '@/components/atom/AddButton'
import Loader from '@/components/atom/LoaderComponent'

export default {
    name: 'JournalCreateEdit',
    components: {
        TitleButton,
        Ledger,
        AddButton,
        Loader
    },
    data: () => ({
        sheet: {},
        selectedFile: null,
        fileName: '',
        isUploading: false,
        accountHeads: [],
        saveNewLoader: false,
    }),
    computed: {
        companyId () {
            return this.$route.params.companyId
        },
        uploadLogId () {
            return this.$route.params.uploadLogId
        },

        isLoading () {
            return this.loading || this.saveNewLoader
        },
        start () {
            return this.$route.query.start
        },
        end () {
            return this.$route.query.end
        },
        isCreatePage() {
            return this.$route.name === 'journal-import'
        },
        progress () {
            return this.$store.state.progress
        }
    },
    methods: {
        ...mapMutations({
            updateProgress: 'updateProgress'
        }),

        onClickStore() {
            let storeValidity = this.checkStoreValidity()
            if(!storeValidity) {
                this.showError('Manually fix all entry first');
                return
            }

           this.findUploadableItem();
        },

        findUploadableItem() {
            let item;
            Object.keys(this.sheet.ledgers).forEach(key => {
                if(this.sheet.ledgers[key].status == 'pending') {
                    item = {
                        item: this.sheet.ledgers[key],
                        key: key
                    }
                    return
                }
            })
            if(!item) {
                this.showSuccess('All item upload finish')
                this.loading = false
                setTimeout(() => {
                    this.goToList()
                }, 1000)
            }
            if(item) this.storeLedger(item)
        },

        async storeLedger(item) {

            let data = {
                company_id: this.companyId,
                voucher_no: item.key
            }

            try {
                this.loading = true
                let res = await this.storeIndividual(this.sheet.id, data)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    setTimeout(() => {
                        item.item.status = 'Completed'
                        this.findUploadableItem()
                    },1000)
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }


        },

        checkStoreValidity() {
            let option = true;
             Object.keys(this.sheet.ledgers).forEach(key => {
                 this.sheet.ledgers[key].ledgers.forEach(ledger => {
                    if(ledger.validation_account_head == false) {
                        option = false
                        return
                    } 

                    else if(ledger.validation_date == false) {
                        option = false
                        return
                    }
                    else if(ledger.validation_debit_credit == false) {
                        option = false
                        return
                    }
                    // else {
                    //     option = true
                    //     return
                    // }
                }) 
            })
            return option
        },

        async onclickUpdate (data) {
            let fd = new FormData();
            fd.append('company_id', this.companyId)
            fd.append('voucher_no', data.keyName)
            fd.append('ledgers', JSON.stringify(data.ledger.ledgers))
            try {
                this.loading = true
                let res = await this.updateIndividual(this.sheet.id, fd)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.getSheetData()
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },
        async getSheetData() {
            let uploadId = this.uploadLogId ? this.uploadLogId : this.sheet.id
           // return;
            try {
                this.loading = true
                let res = await this.fetchSheet(uploadId, `?company_id=${this.companyId}`)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.sheet = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        async getAccountHeads() {
           // return;
            try {
                this.loading = true
                let res = await this.fetchAccountHeads(this.$route.params.companyId)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.accountHeads = res.data
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
            }
        },

        async uploadJournalOnServer(data) {
           // return;
            try {
                this.isUploading = true
                let res = await this.uploadJournal(data)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.sheet = res.data
                    this.selectedFile = null
                    this.fileName = ''
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.isUploading = false
                this.updateProgress(0)
            }
        },

        handleClick() {
            if(this.isCreatePage) {
                this.onClickBrowse()
                return
            } else {
                this.goToList()
            }
        },

        goToList () {
            this.$router.push({name: 'journal-import-list', params: this.$route.params, query: this.$route.query})
        },
        
        onClickBrowse() {
            this.$refs.uploader.click()
        },

        onClose(index) {
            this.journal.general_ledgers.splice(index, 1)
        },

        getFormData () {
            let formData = new FormData();
            Object.keys(this.journal).forEach(i => {
                if(i !== 'general_ledgers') {
                    formData.append(i, this.journal[i])
                }
            })
            formData.append('general_ledgers', JSON.stringify(this.journal.general_ledgers))
            return formData;
        },

        async saveJournal (redirect = false) {//createJournal
            this.journal.company_id = this.$route.params.companyId;
            let data = this.getFormData();
            if(redirect) {
                this.loading = true
            } else {
                this.saveNewLoader = true
            }
            try {
                let res = await this.createJournal(data)
                if(!res.status) {
                    this.showError(res.message)
                }
                if(res.status) {
                    this.showSuccess(res.message)
                    this.resetForm()
                    if(redirect) this.goToList()
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response) {
                    this.showError(err.response.message)
                }
            } finally {
                this.loading = false
                this.saveNewLoader = false
            }
        },

        handleFileUpload (event) {
            this.selectedFile = event.target.files[0];
            this.fileName = event.target.files[0].name
            let fd = new FormData();
            fd.append('company_id', this.companyId)
            fd.append('note', 'Note')
            fd.append('mop_references', 'mop ')
            fd.append('sheet', this.selectedFile)
            this.uploadJournalOnServer(fd)
        }
    },

    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');

        const {
            fetchAccountHeads,
            updateIndividual,
            storeIndividual,
            fetchSheet,
            createJournal,
            uploadJournal,
            loading
        } = handleJournal()

        return {
            fetchAccountHeads,
            updateIndividual,
            storeIndividual,
            fetchSheet,
            createJournal,
            uploadJournal,
            loading,
            showError,
            showSuccess
        }
    },

    mounted() {
        if(!this.isCreatePage) {
            this.getSheetData()
        }
        this.getAccountHeads()
    }
}
</script>

<style scoped>
    .cancel-btn{
        background-color: #F0F2F5; color: #7D7D7D;
    }
    .cancel-btn:hover{
        background-color: #e9edf5;
    }
    hr{
        margin: 0 -3%;
        width: 106%;
    }

    .btn-close {
        width: 30px;
        height: 30px;
        background-color: #E4E6EB;
        border-radius: 50%;
    }
    .btn-close:hover {
        background-color: #D8DADF;
    }
</style>
